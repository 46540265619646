import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Pages/Home';
import { createGlobalStyle } from 'styled-components';
import TempWorking from './Pages/TempWorking';
import ContactUs from './Pages/ContactUs';
import Committee from './Pages/Committee';
import Attraction from './Pages/Attraction';
import Travel from './Pages/Travel';
import Accomodation from './Pages/Accomodation';
import SubmitPaper from './Pages/SubmitPaper';
import Awards from './Pages/Awards';
import SponsorPage from './Pages/SponsorPage';
import SponsorShip from './Pages/SponsorShip';
import Promotions from './Pages/Promotions';
import PaperSubmit from './Pages/Paper_Submit';
import SplashScreen from './Components/SplashScreen';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
  }
`;

function App() {
  return (
    <>
      <SplashScreen />
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/work-in-progress" element={<TempWorking />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/committee" element={<Committee />} />
          <Route path="/nearby-attractions" element={<Attraction />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/accomodation" element={<Accomodation />} />
          <Route path="/submit-a-paper" element={<SubmitPaper />} />
          <Route path="/Paper-Submission" element={<PaperSubmit />} />
          <Route path="/students-awards" element={<Awards />} />
          <Route path="/our-sponsor" element={<SponsorPage />} />
          <Route path="/sponsorship" element={<SponsorShip />} />
          <Route path="/promotions" element={<Promotions />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
