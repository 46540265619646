import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const SplashContainer = styled.div`
  background-color: white;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const Announcement = styled.p`
  font-size: 20px;
  color: #214B8C;
  margin-bottom: 10px;
`;

const AnnouncementLink = styled.a`
  color: #ff6347;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
`;

const CloseButton = styled.button`
  margin: 20px;
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #214B8C;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #17376a;
  }
`;

const SplashScreen = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    setShowSplash(true);
  }, []);

  const handleClose = () => {
    setShowSplash(false);
  };

  if (!showSplash) return null;

  return (
    <Overlay>
      <SplashContainer>
        <Announcement>📢 Important Announcement!</Announcement>
        <Announcement>Paper Submission Deadline is December 31, 2024</Announcement>
        <AnnouncementLink href="https://cmt3.research.microsoft.com/User/Login?ReturnUrl=%2FWAMS2025" target="_blank">Click Here
        </AnnouncementLink>
        <br />
        <CloseButton onClick={handleClose}>Close</CloseButton>
      </SplashContainer>
    </Overlay>
  );
};

export default SplashScreen;
